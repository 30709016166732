<template>
    <panel-alert title="选择" :isShowInfo="false" isShowBottom width="450px" @onCloseClick="onCloseClick"
        @onConfirmClick="onConfirmClick">
        <template v-slot:content>
            <div class="edit-alert">
                <div class="key-value-item key-value-item-info" v-for="item, index in currentQuestionList" :key="item.id">
                    <div class="question-title">{{ item.problemTitle }}</div>
                    <template v-if="item.answerType == 1">
                        <el-select v-model="currentAnswerList[index]" :popper-append-to-body="false" collapse-tags
                            placeholder="请选择" @change="e => singleChoiceChange(e, item, index)">
                            <el-option v-for="item1 in item.answerList" :key="item1.id" :label="item1.answer"
                                :value="item1.id">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.answerType == 2">
                        <el-select v-model="currentAnswerList[index]" :popper-append-to-body="false" multiple
                            placeholder="请选择">
                            <el-option v-for="item1 in item.answerList" :key="item1.id" :label="item1.answer"
                                :value="item1.id">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-if="item.answerType == 11">
                        <el-select v-model="currentAnswerList[index]" :popper-append-to-body="false" placeholder="请选择">
                            <el-option v-for="item1 in item.answerList" :key="item1.id" :label="item1.answer"
                                :value="item1.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import {
    Select,
    Option
} from "element-ui";

export default {
    components: {
        PanelAlert,
        //element
        elSelect: Select,
        elOption: Option,
    },

    props: {
        obj: Object,
    },

    data: function () {
        return {
            currentQuestionList: [],
            currentAnswerList: []
        };
    },

    computed: {

    },


    created() {

    },

    watch: {
        obj: {
            handler(newVal, oldVal) {
                if (!newVal) return
                console.log(newVal)
                if (newVal.item.id === 123) {
                    const question124 = newVal.questionList.find(el => el.id == 124)
                    this.currentQuestionList.splice(0, this.currentQuestionList.length, newVal.item, question124)
                } else {
                    this.currentQuestionList.splice(0, this.currentQuestionList.length, newVal.item)
                }
                const userAnswer = newVal.userAnswerList.find(el => el.problemId == newVal.item.id)
                if (newVal.item.answerType == 1) {
                    const answer = newVal.item.answerList.find(el => el.id == userAnswer.singleChoiceAnswerId)
                    this.currentAnswerList.splice(0, this.currentQuestionList.length, answer?.id)
                    if (answer?.havaChildren) {
                        const child = newVal.childernQuestionList.filter(el => answer.childrenProblemIds.includes(el.id))
                        this.currentQuestionList.push(...child)
                        child.forEach(el => {
                            const childUserAnswer = newVal.userAnswerList.find(el1 => el1.problemId == el.id)
                            const childAnswer = el.answerList.find(el1 => el1.id == childUserAnswer.singleChoiceAnswerId)
                            this.currentAnswerList.push(childAnswer.id)
                        })
                    }
                    //id 123 124合并成一题
                    if (newVal.item.id === 123) {
                        const question124 = newVal.questionList.find(el => el.id == 124)
                        const answer124 = newVal.userAnswerList.find(el => el.problemId == 124)
                        const answer124Id = question124.answerList.find(el => el.id == answer124.singleChoiceAnswerId)
                        this.currentAnswerList.push(answer124Id.id)
                    }
                } else if (newVal.item.answerType == 2) {
                    const answers = newVal.item.answerList.filter(el => userAnswer.manyChoiceAnswerId.includes(el.id))
                    this.currentAnswerList.splice(0, this.currentAnswerList.length, answers.map(el => el.id))
                } else if (newVal.item.answerType == 11) {
                    const answer = newVal.item.answerList.find(el => el.id == userAnswer.singleChoiceAnswerId)
                    this.currentAnswerList.splice(0, this.currentAnswerList.length, answer.id)
                }

            },
            immediate: true
        }
    },

    methods: {
        singleChoiceChange(e, item, index) {
            const answer = item.answerList.find(el => el.id == e)
            if (item.id === 123 || item.id === 124) {
                return
            }
            if (answer.havaChildren) {
                const child = this.obj.childernQuestionList.filter(el => answer.childrenProblemIds.includes(el.id))
                this.currentQuestionList.push(...child)
            } else {
                this.currentQuestionList.splice(1,this.currentQuestionList.length - 1)
            }
        },

        onCloseClick: function () {
            this.$emit("onCloseClick");
        },

        onConfirmClick: function () {
            for (let index = 0, length = this.currentQuestionList.length; index < length; index += 1) {
                const element = this.currentQuestionList[index];
                const answer = this.currentAnswerList[index]
                if (!answer || answer.length == 0) {
                    this.$toast.showRed(`请选择${element.problemTitle}`)
                    return
                }
            }
            const questionList = []
            this.obj.userAnswerList.forEach(el => {
                let find = false
                this.currentQuestionList.forEach((el1, index1) => {
                    if (el.problemId == el1.id) {
                        find = true
                        if (el1.answerType == 1) {
                            questionList.push({
                                problemId: el.problemId,
                                singleChoiceAnswerId: this.currentAnswerList[index1]
                            })
                        } else if (el1.answerType == 2) {
                            questionList.push({
                                problemId: el.problemId,
                                manyChoiceAnswerId: this.currentAnswerList[index1]
                            })
                        } else if (el1.answerType == 11) {
                            questionList.push({
                                problemId: el.problemId,
                                singleChoiceAnswerId: this.currentAnswerList[index1]
                            })
                        }
                    }
                })
                if (!find) {
                    questionList.push(el)
                }
            })
            this.$emit("onSucceed", questionList);
        },
    }
}
</script>
  
<style lang="scss" scoped>
/deep/.panel-alert-content {
    padding-right: 24px;
    padding-bottom: 10px;
}

/deep/.panel-alert-bottom {
    border: none;
}

.edit-alert {
    &-content {
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        box-sizing: border-box;
        padding-right: 20px;
    }

    &-line {
        background-color: rgba($color: #000000, $alpha: 0.09);
        width: 100%;
        height: 1px;
        margin-top: 9px;
    }
}

.key-value-item {
    margin-top: 9px;
    margin-bottom: 15px;

    .question-title {
        margin-bottom: 10px;
        width: 100%;
        min-height: 30px;
        font-size: 14px;
        line-height: 30px;
    }

    /deep/.el-input__inner {
        font-size: 14px;
        height: 32px;
        width: 142px;
    }

    /deep/.el-input__icon {
        line-height: 32px;
    }

    /deep/.el-select-dropdown__item {
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            margin-left: 10px;
        }
    }

    &-info {
        /deep/.el-input__inner {
            width: 400px;
        }
    }
}
</style>
  
  